// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './animations';

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('assets/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('assets/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}



html {
  .md,
  .ios,
  body {
    font-family: 'Roboto', serif;
  }

  body {
    color: rgb(84, 83, 83);
  }
}

.accordion-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}



.btn-col {
  display: flex;
  align-items: flex-end;

  ion-item {
    flex-grow: 1;
  }

  ion-button {
    flex-shrink: 0;
  }
}

.buttons {
  &__success {
    color: rgb(33, 227, 138);
  }
}

.search-card {
  border-bottom: 4px solid rgb(211, 219, 230);
}

.search-card__header-container {
  width: 100%;
  margin-top: 6px;
}

.content {
  &__body {
    padding: 30px 33px;
    margin: 0 auto 22px auto;
  }

  &__padding {
    --padding-top: 0px;
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;

    &--mobile {
      --padding-top: 0px;
      --padding-start: 0;
      --padding-end: 0;
      --padding-bottom: 0;
    }
  }

  &__spacer {
    display: block;
    padding-top: 10px;
  }

  &__spacer--right  {
    padding-right: 8px;
  }

  &__spacer--left  {
    padding-left: 8px;
  }

  &__spacer-double {
    padding-top: 60px;
  }
}

ion-content {
  display: block;
  position: relative;
}

ion-select,
ion-datetime {
  --padding-start: 6px;
}

ion-checkbox {
  --border-radius: 45%;
  min-height: 30px;
  min-width: 30px;
}

.variants-bar {
  height: 60px;
  background: rgb(210, 218, 229);
}

.fixed-position-action-control-bar {
  z-index: 1000;
  width: 100%;
  background: rgb(255, 255, 255);
}


ion-button.fa-icon-ion-button {
  font-size: 25px;
  color: var(--common-blue-text);
  &:hover {
    background: rgba(255, 255, 2555, 0.1);
    border-radius: 45px;
  }
}

.select-container {
  position: relative;
  display: flex;
  align-items: center;

  select {
    -webkit-appearance: none;
    min-width: 120px;
    padding: 8px 8px;
    border: var(--common-grey-border);
    background: white;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    width: 100%;
    color: var(--common-blue-text)
  }

  .icon {
    position: absolute;
    font-size: 12px;
    right: 8px;
  }
}


ion-button.fa-icon-ion-button-transparent {
  font-size: 25px;
  color: var(--common-blue-text);
  background: transparent;
}

.search-card {
  background: white;
  border-bottom: 1px solid rgb(222, 228, 236);
  width: 100%;
}

.is-card-selected {
  background: var(--common-selected-item-color) !important;
}

.alternate-row-background  {
  background: rgba(247, 248, 249, 0.98);
}

.action-icon  {
  &:hover  {
    opacity: 0.7;
  }
}

.text__accordion {
  color: #5d5d5d;
  font-weight: bold;
}

.fal.medium {
  font-size: 24px;
}

.input[type=text], .input[type=number], textarea.input {
  font-size: 1em;
  outline: 0;
  border-radius: 3px;
  border: 1px solid  var(--input-border-color);
  padding: 8px;
  margin-top: 8px;
  flex-grow: 1;
}


.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.full-size-drawing-modal{
  --height: 100%;
  --width: 100%;
}

.qr-popup {
  --width: 500px;
  --height: 550px;
}

.medium-icon {
  font-size: 1.5em;
}

.comma-separator {
  * + * {
    &::before {
      content: ', ';
    }
  }
}

@mixin input-field()  {
  font-size: 1em;
  outline: 0;
  border-radius: 6px;
  border: 2px solid var(--input-border-color);
  padding: 8px;
  margin-top: 8px;
  flex-grow: 1;

  &[ng-reflect-readonly=true] {
    background: #edf0f5;
  }
}

.value-input {
  @include input-field();
}


.value-checkbox {
  margin-top: 8px;
}

@mixin state-model-color($color) {
  background: var($color);
}

.state-model-phase-colors {
  color: #fff;

  &--is-active {
    color: #fff;
    font-weight: bold;
  }

  &--is-past {
    color: var( --light-grey-text);
    filter: brightness(114%);
  }

  &--is-future {
    background: #f5f5f5;
  }

  &--unknown {
    @include state-model-color(--common-sales-order-phase-unknown);
  }

  &--creation {
    @include state-model-color(--common-sales-order-phase-creation);
  }

  &--processing {
    @include state-model-color(--common-sales-order-phase-processing);
  }

  &--submission {
    @include state-model-color(--common-sales-order-phase-submission);
  }

  &--billing {
    @include state-model-color(--common-sales-order-phase-billing);
    color: #ffffff;
  }

  &--error {
    @include state-model-color(--common-sales-order-phase-error);
    color: #ffffff;
  }
}

.text {
  &__header {
    color: var(--common-gray-text);
    line-height: 12px;
    font-size: 12px;
    text-transform: initial;
  }

  &__properties {
    color: var(--common-blue-text);
    font-size: 14px;
  }

  &__title-1 {
    color: var(--common-blue-text);
    font-size: 14px;
  }

  &__title-2 {
    color: var(--common-blue-text);
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    white-space: normal;
    word-break: break-word;
  }

  &__detail {
    color: var(--common-blue-text);
    font-size: 12px;
    line-height: 22px;
  }

  &__description {
    color: var(--common-text-grey-color);
    font-size: 18px;
    line-height: 22px;
  }

  &__body {
    color: var(--common-blue-text);
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }

  &__accordion {
    color: var(--common-blue-text);
    font-size: 1em;
    line-height: 26px;
  }

  &__section {
    color: var(--common-gray-text);
    line-height: 20px;
    font-size: 14px;

    &.red {
      color: rgb(199, 94, 92);
    }
  }

  &__small {
    line-height: 12px;
    font-size: 12px;
  }

  &__footer {
    color: var(--common-blue-text);
    font-size: 14px;
    line-height: 26px;
  }

  &--blue  {
    color: var(--common-blue-text);
  }
}

@mixin box-shadow-left() {
  box-shadow: -2px 5px 11px 3px #d4d2d2;
}

@mixin box-shadow-top() {
  box-shadow: 0 -4px 10px #d4d2d2;
}

.box-shadow {
  &__top {
    @include box-shadow-top();
  }

  &__left {
    @include box-shadow-left();
  }

  &__standout {
    box-shadow: 0 -2px 6px #d4d2d2;
  }
}

.clickable {
  cursor: pointer;
  color: var(--common-clickable-color);
  text-decoration: none;
}

.highlight {
  font-weight: bold;
}

// scrolling workaround: full-screen action sheet scrolling background
.action-sheet-group.sc-ion-action-sheet-md,
.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto;
}

// scrolling workaround: action sheet not honoring safe area margins
.action-sheet-wrapper.sc-ion-action-sheet-md,
.action-sheet-wrapper.sc-ion-action-sheet-ios {
  top: 0;
  height: auto;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}
