:root {
  --common-ortho-pink: rgba(212, 41, 101, 0.8);
  --common-ortho-blue: rgb(0, 48, 90);
  --common-badge-color: #00477F;

  --common-ortho-red: #AA0202;

  --common-accordion-border-bottom: 1px solid rgb(210, 218, 229);
  --common-accordion-border-top: 1px solid #d2dae5;
  --common-accordion-color-background: rgb(226, 230, 233);
  --common-grey-border: 1px solid #d2dae5;
  --common-edit-field-border-color: #e6e6e6;

  --common-searchbar-background: rgb(163,	181,	202);
  --common-searchbar-color: #fff;
  --common-searchbar-padding: 8px;
  --common-searchbar-border-radius: 6px;

  --common-avatar-border: none;

  --common-search-list-border-right: inherit;

  --common-toolbar-background: #00305a;
  --common-toolbar-color: rgb(226, 230, 233);
  --common-secondary-toolbar-background: rgba(247, 248, 249, 0.98);
  --common-secondary-toolbar-color: #898686;
  --common-secondary-toolbar-color-active: #4c4a4a;

  --common-list-item-background: #f7f8f9;
  --common-list-item-alt-background: #fff;

  --common-link-bar-background: #d2dae5;;
  --common-link-bar-color: #00305a;

  --common-blue-text: #00305A;
  --common-gray-text: #414445;
  --common-gray-background: #666666;
  --common-gray-color: #bebebe;
  --common-clickable-color: #3f6691;
  --common-light-pink-color: #dfb0bf;

  --common-pending-color: #f6b93b;
  --common-error-color:  #e55039;
  --common-success-color: #78e08f;
}


.search-card {
  border-bottom: 4px solid rgb(211, 219, 230);
}

.search-card__header-container {
  width: 100%;
  margin-top: 6px;
}

.text {
  &__header {
    color: rgb(154, 154, 154);
    line-height: 12px;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__properties {
    color: rgb(84, 83, 83);
    font-size: 14px;
  }

  &__title-1 {
    color: rgb(82, 137, 202);
    font-size: 16px;
    line-height: 30px;
  }

  &__title-2 {
    color: rgb(0, 48, 90);
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    white-space: normal;
  }

  &__detail {
    color: rgb(124, 150, 180);
    font-size: 12px;
    line-height: 22px;
  }

  &__body {
    color: rgb(0, 48, 90);
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }

  &__accordion {
    color: rgb(0, 48, 90);
    font-size: 1em;
    line-height: 26px;
  }

  &__section {
    color: rgb(154, 154, 154);
    line-height: 20px;
    font-size: 16px;

    &.red {
      color: rgb(199, 94, 92);
    }
  }

  &__small {
    line-height: 12px;
    font-size: 12px;
  }

  &__footer {
    color: rgb(0, 48, 90);
    font-size: 14px;
    line-height: 26px;
  }
}

.space-between {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.qr-code {
  background: url("../icons/qr-code.svg") no-repeat;
}

.action-icon {
  font-size: 30px;
  padding: 8px;
}
