/* You can add global styles to this file, and also import other style files */
//@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.expected-size {
  z-index: 1000;
  background: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


@media (min-width: 1000px) {
  .expected-size {
    display: none;
  }
}

.accordion-padding {
  padding: 4px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.no-shrink {
  flex-shrink: 0;
}
.history-input {
  background: white;
}
textarea.history-input {
  width: 100%;
  outline: 0;
  border-radius: 3px;
  border: 1px solid #D8DEE9;
  padding: 8px;
  -webkit-appearance: none;
}
.history-comment-entry {
  width: 100%;
  padding: 0;
  border: 0;
  overflow: hidden;
  resize: none;
}
textarea {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--common-blue-text);
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--common-blue-text);
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--common-blue-text);
  }
}
.dropdown-menu {
  list-style-type: none;
  z-index: 100;

  li {
    border: 1px solid var(--common-toolbar-color);
    border-bottom: none;
    padding: 8px 8px;
    background: white;
  }

  li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  li:last-child {
    border-bottom: 1px solid var(--common-toolbar-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .dropdown-item {
    text-decoration: none;
    margin-left: 4px;
    margin-right: 4px;
  }

  li.active {
    background: #cddae5;
    color: var(--common-blue-text);
  }
}

.highlighter.history-comment-entry {
  z-index: 10;
  color: var( --light-grey-text);

  wunder-highlighted {
    background: #cddae5;
    border-radius: 4px;
    color: var(--common-blue-text);
  }
}

.small-modal {
  margin: auto;
  .modal-wrapper {
    width: 400px;
    height: 20vh;
    transition: height 0.15s ease-out;
    transition: width 0.15s ease-out;
  }
}

.sales-order-duplicate-modal {
  .modal-wrapper {
    width: 600px;
    height: 600px;
  }
}

.customer-create-sales-order-modal  {
  .modal-wrapper {
    height: 245px;
  }
}

.customer-duplicate-modal  {
  .modal-wrapper {
    width: 850px;
    height: 650px;
  }
}

.customer-create-sales-order-rental-modal  {
  .modal-wrapper {
    height: 275px;
  }
}

mentions-list {
  z-index: 100;
}

.native-textarea.sc-ion-textarea-ios {
  padding: 8px;
}

.textarea-disable-resize {
  resize: none;
}

.ellipse {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal-max-width {
  .modal-wrapper {
    max-width: 600px;
  }
}

.input-clear-icon.sc-ion-input-md {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='var(--ion-color-step-600,%20%23666666)'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>");
}


.assistant {
  bottom: 100px !important;
  right: 24px !important;
  max-width: calc(100vw - 48px) !important;
  max-height: calc(100vh - 120px) !important;
  z-index: 1 !important;
}
