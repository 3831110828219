// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #00477f;
  --ion-color-primary-rgb: 0, 71, 127;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #d42965;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #18b56d;
  --ion-color-success-rgb: 24, 181, 109;
  --ion-color-success-contrast: rgb(252, 254, 255);
  --ion-color-success-contrast-rgb: 0, 48, 90;
  --ion-color-success-shade: #18b56d;
  --ion-color-success-tint: #18b56d;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: rgba(199, 94, 92);
  --ion-color-danger-rgb: 99, 94, 92;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: rgb(142, 164, 189);
  --ion-color-medium-rgb: 142, 164, 189;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: var(--common-toolbar-color);
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: OrthoFont, Roboto, serif;
  --ion-default-font: OrthoFont, Roboto, serif;
  --bullet-background: rgb(0, 48, 90);


  // common lib theming
  --common-accordion-border-bottom: 1px solid rgb(245, 245, 245);
  --common-accordion-border-top: 1px solid rgb(245, 245, 245);
  --common-accordion-color-background: rgb(245, 245, 245);

  --common-searchbar-background: rgb(245, 245, 245);
  --common-searchbar-color: #00305a;
  --common-searchbar-padding: 22px 12px;

  --common-avatar-border: 2px solid #83909B;

  --common-search-list-border-right: 1px solid #e8e5e5;

  --common-toolbar-background: rgb(255, 255, 255);
  --common-toolbar-color: #777;

  --common-link-bar-background: var(--common-toolbar-background);;
  --common-link-bar-color: var(--common-toolbar-color);

  --common-sales-order-phase-creation: #ffa72f;
  --common-sales-order-phase-processing: #78e08f;
  --common-sales-order-phase-submission: #3e8de2;
  --common-sales-order-phase-billing: #805be3;
  --common-sales-order-phase-error: #e55039;
  --common-sales-order-phase-unknown: #b1b1b1;

  --common-text-grey-color: #9a9a9a;
  --common-kv-status: #6F07D0;
  --common-edit-label: #4688C7;
  --light-grey-text: #545353;

  --input-border-color: #D8DEE9;

  --common-selected-item-color: #E8EBF2;

  --common-edit-field-border: 2px solid var(--input-border-color);

  .header-md:after {
    display: none;
  }

  ion-button {
    border-radius: 4px;
    --border-radius: 4px;
    font-size: 12px;
    --padding-start: 10px;
    --padding-end: 10px;
    --background-activated: #00477f;
    font-weight: bold;


    ion-icon {
      padding-right: 8px;
    }
  }
}

